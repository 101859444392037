import * as React from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Vimeo from "@u-wave/react-vimeo";
import type Player from "@vimeo/player";

import {
  Layout,
  Section,
  Header,
  SEO,
  WideContent,
  BadgeContainer,
  Badge,
  BadgePlatform,
} from "../components";

const VIDEO = "https://vimeo.com/722159399/509fc8ae62";

const MobileVideoPage: React.FunctionComponent = () => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const setTextTrack = async (player: Player) => {
    const textTracks = await player.getTextTracks();

    // Try to find an exact match
    let match = textTracks.find((track) => track.language === language);

    // If no exact match was found, try to find by just the language code
    // portion. For example, 'es' from 'es-409'.
    if (!match) {
      const [langCode] = language.split("-");
      match = textTracks.find((track) => track.language === langCode);
    }

    // If a matching text track was found, enable it in the player
    if (match) {
      player.enableTextTrack(match.language);
    }
  };

  return (
    <Layout>
      <SEO title={t(["mobile_video.title", "home.title"])} />
      <WideContent>
        <Header />
        <Section>
          <Vimeo video={VIDEO} responsive onReady={setTextTrack} dnt />
        </Section>
        <Section>
          <BadgeContainer center={true}>
            <Badge platform={BadgePlatform.Apple} />
            <Badge platform={BadgePlatform.Google} />
          </BadgeContainer>
        </Section>
      </WideContent>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default MobileVideoPage;
